const projects = [
    {   
        id:1, 
        title: 'Nasa Api App',
        description: 'A ReactJS Web Application, fethcing data from Nasa"s public restful API. Fully responsive, network connections, and a few features like Photo Of The Day',
        url: 'https://astronomy-gallery.netlify.app/',
        image: "https://i.ibb.co/MkrsbMW/Screen-Shot-2022-04-02-at-4-00-43-PM.png",
        details: [
            " Experiment, explored, and developed UI ",  
            " Built fetch request methods to retrieve data from api ",
            " Continuous integration and Continuous deployment ",
            " Open Source Project Maintainer ",
            " Fully responsive web app "
        ]
    },
    {
        id: 2,
        title: 'Miracle Messages',
        description: 'Contributed to a API Map where users can help pin point exact locations of neighbors who are in search of their loved ones, or trying to get back home.',
        url: 'https://www.miraclemessages.org/impact',
        image: "https://i.ibb.co/82XHHpn/Screen-Shot-2021-02-18-at-8-59-59-PM.png",
        details: [
            " Team Lead / oversee all of project ",  
            " Creating and intergrating user interfaces with ux/ui designers ",
            " Mentor Junior Devs / help build reusable components ",
            " Analytical, problem solving, communication skills ",
            " Code reviews, hold meetings, setting up development plans "
        ]
    },
    {
        id: 3,
        title: 'CanJS Guide',
        description: 'Contributed to a begginers guide on a Javascript Framework known as CanJS. Not only helping develop a guide but also had the privilage to share it with others in a local in person meetup.',
        url: 'https://canjs.com/doc/guides/recipes/video-player.html',
        image: "https://i.ibb.co/yy9LXz7/Screen-Shot-2021-02-18-at-9-01-41-PM.png",
        details: [
            " Created begginner guides for the framework ",  
            " Contrubuting to open source and teached local self learning developers ",
            " Maintain documentation, testing and reporting bugs, writing and updating software ",
            " Helping new users  ",
            " Fully responsive web app "
        ]
    },
    {
        id: 4,
        title: 'Vianna Brothers Jiu Jitsu',
        description: 'Helped my jiu jitsu academy with their own website, with an about page, schedule information, contact information, and more.',
        url: 'https://www.viannabrothers.com/',
        image: "https://i.ibb.co/52gBkcT/Screen-Shot-2021-02-18-at-8-44-58-PM.png",
        details: [
            " Implement and manage website on wordpress ",  
            " Troubleshooting and debugging code, adding and install widgets ",
            " Continuous integration and Continuous deployment ",
            " Analyzing existing programs for modification purposes ",
        ]
    },
    {
        id:5,
        title: 'MCU React App',
        description: 'A simple ReactJS application, with a few dependencies like React-Router-Dom, Bootstrap, and more.',
        url:'https://mcu-avengers.netlify.app',
        image: 'https://i.ibb.co/fHCWwhf/Screen-Shot-2022-01-09-at-12-20-32-PM.png',
        details: [
            " ReactJS Single Page Application ",  
            " Browser compatability  ",
            " Continuous integration and Continuous deployment ",
            " Open Source Project Maintainer ",
            " Fully responsive web app "
        ]
    }
    
]


export default projects;