import React from 'react';

export default function Resume(){
    
    return(
        <div className='bg-[#053B50] mx-2 lg:w-1/2 border-[#EEEEEE] border-2 border-solid px-4 py-6 md:mx-auto my-10 text-[#EEEEEE] font-til'>

            {/* Resume Header */}
            <div className=''>
                <p className='text-xs lg:text-base border-t-2 border-dashed pt-2'>Digital Resume </p>

                <p className='text-center text-3xl lg:text-6xl my-2 '>Front-End Developer</p>

                <p className='text-center text-2xl lg:text-5xl '>Oscar P Ortiz</p>

                <p className='text-center text-xl lg:text-4xl my-2  pb-2'>Resume</p>
            </div>

            <div className='lg:flex lg:flex-row border-t-2 border-dashed'>

                {/* Resume Description */}
                <div className='text-lg py-4 w-auto lg:w-1/2  mx-auto lg:pr-2 border-dashed lg:border-r-2'>
                    <p>
                    I'm a <b>Front-End Developer</b> based in Chicago with two years of experience  in the web industry. For the past few years, my focus area has been front-end development with React.
                    </p>
                </div>

                {/* Contact info */}
                <div className='py-4 border-t-2 border-dashed lg:border-y-0 lg:w-1/2  lg:pl-2 lg:my-auto'>
                     <h2 className="text-xl text-center ">Contact Information</h2>

                    <ul className='text-center'>
                        <li>
                        Email: Oscarpa9188@gmail.com
                        </li>
                        <li>
                        Github: <a target='_blank' href='https://github.com/CleverOscar'>https://github.com/CleverOscar</a>
                        </li>
                        <li>
                        LinkedIn: <a target='_blank' href='https://www.linkedin.com/in/clever-oscar/'> https://www.linkedin.com/in/clever-oscar/ </a>
                        </li>
                    </ul>
                </div>

            </div>

            {/* Experience */}

            <div className='py-4 sm:border-t-2 sm:border-dashed'>

                <p className='text-3xl text-center pb-4'>Experience</p>
                
                {/* Upwork */}
                <div className='border-t-2 border-dashed py-6 text-2xl text-center'>
                    <p>
                        Upwork Freelance Developer
                    </p>
                    
                    <a href='https://www.upwork.com/freelancers/oscarp2' target='_blank' className='hover:underline text-blue-500 font-bold'>
                        Want to hire me? 
                    </a>
                </div>

                {/* Bloomtech */}
                <div className='border-t-2 border-dashed py-6'>
                    <p className='text-2xl'>
                        Team Lead / Project Manager
                    </p>
                    <p className='text-xl'>
                        Bloom Institute of Technology
                    </p>

                    <ul className='text-lg list-disc px-5' >
                        <li>
                            Managed a team of 8 members, including six web developers and two web designers, to achieve exceptional outcomes.
                        </li>
                        <li>
                            Showcased effective leadership, communication, and problem-solving skills while cultivating a cooperative and high-productivity team atmosphere.
                        </li>
                        <li>
                            Organized client meetings to discuss the application process and played a pivotal role  in guiding vital decisions during client consultations.
                        </li>
                        <li>
                            Encouraged ongoing learning and professional growth among the team members.
                        </li>
                    </ul>
                </div>

                
                {/* Bitovi */}
                <div className='border-t-2 border-dashed py-6'>
                    <p className='text-2xl'>
                        Javascript Developer Intern
                    </p>
                    <p className='text-xl'>
                        Bitovi
                    </p>

                    <ul className='text-lg list-disc px-5' >
                        <li>
                            Performed code reviews and offered assistance for open-source code within the CanJS framework.
                        </li>
                        <li>
                            Leveraged JavaScript expertise to develop a beginner-friendly guide on creating a video component using CanJS.
                        </li>
                        <li>
                            Edited and debugged documentation for the CanJS guide, ensuring it adhered to industry-standard coding practices.
                        </li>
                        <li>
                            Organized local Chicago meetups to present guides and foster a stronger developer community.
                        </li>
                    </ul>
                </div>


                {/* Wordpress Developer */}

                <div className='border-t-2 border-dashed py-6'>
                    <p className='text-2xl'>
                        WordPress Web Developer
                    </p>
                    <p className='text-xl'>
                        Vianna Brothers Jiu Jitsu
                    </p>

                    <ul className='text-lg list-disc px-5' >
                        <li>
                            Developed a business website for a local gym, featuring crucial information like schedule, contact details, and more.
                        </li>
                        <li>
                            Implemented a responsive design for enhanced user-friendliness and efficiency of the website.</li>
                        <li>
                            Integrated third-party APIs to augment website functionality, including social media widgets for improved user engagement.
                        </li>
                        <li>
                            Ongoing maintenance and web performance optimization to boost page load times for improved user experience.
                        </li>
                    </ul>
                </div>

            </div>




        </div>
    )
}