import Navbar from "./views/Navbar/Navbar";

import LandingPage from "./views/LandingPage/LandingPage";
import Footer from "./views/Footer/Footer";
import Services from "./views/Services/Services";

import {Route, Routes} from 'react-router-dom'
import Resume from "./views/Resume/Resume";
// 

function App() {
  return (
    <div className="flex flex-col h-screen justify-between">
      <header>
        <Navbar />
      </header>

      <div className="container mx-auto my-auto">
        <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/services" element={<Services />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
