export default function About() {
    return(
        <div className="bg-blue-900 w-auto static max-w-screen-sm mx-auto">
      
                

            <div id="aboutCard" className="bg-[#053B50] w-auto h-auto mx-auto my-10 border-4 border-[#EEEEEE] relative">

                <img src="https://images.unsplash.com/photo-1552594364-1489ef942d3c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" className="absolute" />

                <div className="relative">
                    <img className="w-1/2 h-1/2 mx-auto my-8 rounded-full" src="https://i.ibb.co/6PccNff/45219372-2219308534767966-7383583342043594752-n.jpg" alt="oscar ortiz" border="0" />
                </div>

                <div className="z-10 relative text-[#EEEEEE]">
                    
                    <div className="p-4">
                        <h1 className="text-center text-3xl">
                        Front-End Develoepr
                        </h1>
                        <p className="text-center text-xl ">
                        Oscar Ortiz
                        </p>

                        <p className="text-center text-lg"> About me</p>
                        <p className="">
                        Greetings, I am Oscar, a seasoned JavaScript developer with a specialization in the realm of web development. My expertise spans a spectrum of web development solutions, encompassing single-page applications, responsive designs, static websites, WordPress integrations, and the conversion of PDF documents into HTML.

                        I am dedicated to assisting small enterprises in elevating their website's user experience and optimizing performance to benefit their user base. Should you find yourself in need of assistance with any web-related service, I invite you to explore the comprehensive suite of services that I have to offer. Your satisfaction and the success of your web project are my top priorities. 
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    )
}