import React from 'react';
import FadeInSection from '../Helpers/FadeComponent';

import projects from './data'

export default function Projects(){


    return(

        <div>
            <p className="text-white text-center text-4xl tracking-wider underline">Projects</p>
            <div >
                {displayProjects}
            </div>
        </div>
    )
}



const displayProjects = projects.map(project => 
<FadeInSection > 

   <div key={project.id} className="flex flex-col md:grid md:grid-cols-2 lg:w-8/12 lg:mx-auto  w-auto text-white text-xl bg-gray-900 p-4 text-center rounded-xl shadow-inner shadow-[#8ab5ff] ">

        <div className="md:basis-2/4">

            <p className="text-2xl text-center md:underline md:underline-offset-4 md:text-3xl mb-4">{project.title}</p>

            <p className="text-base text-left px-4 tracking-wider py-2 md:text-xl">{project.description}</p>

        </div>

       <div className="md:basis-2/4">

         <img className="border-2 border-white rounded-lg my-4" src={project.image} alt={project.title} />
         <a href={project.url} className="text-base md:text-3xl text-red-900" target="_blank" rel="noreferrer"> Link To Site </a>
       </div>
     
   </div>



</FadeInSection>);

