import '../Navbar/nav.css'
import {Link} from 'react-router-dom'

export default function Navbar() {
    return(
        <div>
           <nav className="
                    relative
                    w-full
                    nav
                    flex flex-wrap
                    items-center
                    justify-between
                    py-6 
                    focus:text-gray-700
                    shadow
                    shadow-[#D1D1D1]
                    px-4 
                    md:px-0
                    navbar navbar-expand-lg navbar-light
                    ">


                    <div className="container mx-auto w-full flex flex-wrap items-center justify-between">

                        <a className="text-xl  uppercase" href="/">
                            <span className="text-[#4C4C4C]">Clever</span> Oscar
                        </a>

                        <button className="
                            navbar-toggler
                            border-0
                            hover:shadow-none hover:no-underline
                        
                            bg-transparent
                            focus:outline-none focus:ring-0 focus:shadow-none focus:no-underline
                        " type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars"
                            className="w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="currentColor"
                                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
                            </path>
                            </svg>
                        </button>

                        <div className="collapse navbar-collapse flex-grow items-center" id="navbarSupportedContent">

                        <ul className="navbar-nav flex flex-col pl-0 list-style-none ml-auto text-right pt-2 md:pt-0  font-til text-xl">
                            
                            <li className="nav-item">
                                <Link className="nav-link" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to='/resume'>Resume</Link>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link " aria-current="page" href="https://dev.to/cleveroscar" target="_blank" rel="noreferrer">Blog</a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link " aria-current="page" href="https://github.com/CleverOscar" target="_blank" rel="noreferrer">Github</a>
                            </li>
                            <li className="nav-item ">
                                <a className="nav-link " aria-current="page" href="https://www.linkedin.com/in/clever-oscar/" target="_blank" rel="noreferrer">LinkedIn</a>
                            </li>
                        </ul>

                        </div>

                    </div>


            </nav>
        </div>
    )
}