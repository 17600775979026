import About from '../About/AboutComponent';
import Projects from '../Projects/Projects';
import SkillsView from '../Skills/SkillsView';

export default function LandingPage() {
    
    return(
        <div>
            <About />
            {/* <SkillsView />
            <Projects /> */}
            
        </div>
    )
}


 