import {useEffect, useState, useRef} from 'react'

export default function FadeInSection ({children})  {

    // our element refrence
    const domRef = useRef();
    // our element shouldnt be visible from the start
    const [isVisible, setVisible] = useState(false);



    useEffect( () => {
        const observer = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting) {
                setVisible(true)

                observer.unobserve(domRef.current)
            }
        });

        observer.observe(domRef.current);
        
        return () => observer.unobserve(domRef.current);
    }, []);

    return(
        <section ref={domRef} className={"w-auto h-auto " + (isVisible ? ' is-visible' : '')}> 
            {children}
        </section>)

}