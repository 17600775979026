import React from 'react';

export default function Services(){
    return(
        <div className=''>

            <div className='bg-gray-600/50 mt-2'>
                <p className='text-white text-center text-4xl py-8 '>We provide the skills you require</p>
            </div>


            <div className='grid grid-cols-3 gap-4 mt-10'>

                <div className='border-t border-purple-700 w-auto bg-gray-400 p-3'>
                    <p>UX & Interface Development</p>
                    <p>Let us bring your design to life, making it easy to use. Building a better experience </p>
                </div>

                <div className='border-t border-blue-700 w-auto bg-gray-400 p-3'>
                    <p>JavaScript Consulting</p>
                    <p>Let us bring your design to life, making it easy to use. Building a better experience </p>
                </div>
                
                <div className='border-t border-green-700 w-auto bg-gray-400 p-3'>
                    <p>UX & Interface Development</p>
                    <p>Let us bring your design to life, making it easy to use. Building a better experience </p>
                </div>

            </div>

        </div>
    )
}